<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Complete Toolbox Talk</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="4">
                            <p v-if="state === 'initializing' || state === 'loading'" class="text-center">
                                <b-spinner small v-if="state === 'saving'"></b-spinner>Loading...
                            </p>
                            <label v-if="state === 'show' || state === 'showChecklist'">Choose a Toolbox Talk to complete</label>
                            <b-form-select v-model="checklistTypeId" @change="loadChecklistDetails()" :disabled="state === 'initializing'" v-if="state === 'show' || state === 'showChecklist'">
                                <b-form-select-option v-for="(item, index) in allChecklists" :key="index" :value="item">{{item.dueDate | dateFilter}} - {{item.checkList}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" md="8" v-if="state === 'showChecklist'" class="px-0">
                            <complete-checklist />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { bus } from '../../main'
import { mapActions, mapState, mapMutations } from 'vuex'
import completeChecklist from '../../components/completeChecklist.vue'
export default {
  components: { completeChecklist },
    data: () => ({
        state: 'initializing',
        checklistTypeId: null,
        dummyData: [],
        allChecklists: []
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'Checklists'
            },
            {
                text: 'Complete Toolbox Talk',
                active: true
            },
        ])
        
        this.doGetChecklists()
        bus.$on('changeIt', () => {
            this.state = 'show'
            this.doGetChecklists()
            this.checklistTypeId = null
        })
    },
    methods:{
        ...mapActions(['getTodoList', 'getChecklistInformation']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        doGetChecklists(){
            this.getTodoList()
            .then((res) => {
                this.state = 'show'
                this.allChecklists = res.data
            })
        },
        loadChecklistDetails(){
            this.state = 'show'
            let item = {checkListId: this.checklistTypeId.checkListID}
            this.$store.commit('setSelectedChecklist', item)
            this.$store.commit('setScheduleChecklistId', this.checklistTypeId.checkListScheduleID)
            this.getChecklistInformation()
            .then(() => {
                this.state = 'showChecklist'
            })
        }
    },
    computed:{
        ...mapState(['selectedChecklist'])
    }
}
</script>